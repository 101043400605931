import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Form = makeShortcode("Form");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kontakt"
    }}>{`Kontakt`}</h1>
    <Form url="https://form.typeform.com/to/S8izpT" mdxType="Form" />
    <p>{`54610 MOREY / BELLEAU
Telephone: `}<a parentName="p" {...{
        "href": "tel:+33383315098",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`(00 33) 03.83.31.50.98`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.google.com/maps/place/Chateau+de+Morey/@48.8259246,6.1605373,17z/data=!4m11!1m2!3m1!2sChateau+de+Morey!3m7!1s0x4794be4fc120ab4b:0xac344ad4cad6b228!5m2!4m1!1i2!8m2!3d48.8259248!4d6.1627259",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`COORDONEES GPS: 48°49’35’ N / 06°09’45’ E`}</a></p>
    <p>{`RC NANCY: A434743225`}</p>
    <p>{`TVA INTERCOMMUNAUTAIRE : FR 8543474322200014`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      